import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './Feedback.scss';
import PropTypes from 'prop-types';
import { getFeedback, submitFeedback, getFeedbackCategories } from '../../redux/feedback/feedbackActions';
import { convertDateToDateYearCompleteString } from '../../helpers/DateTimeParser';
import { translate } from '../../helpers/translations/translator';

class Feedback extends Component {
    state = {
        selectedCategory: null,
        text: null,
    };

    componentDidMount() {
        const { match, dispatch } = this.props;

        if (match.params.feedbackUuid) {
            dispatch(getFeedbackCategories());
            dispatch(getFeedback(match.params.feedbackUuid));
        }
    }

    onSubmit = () => {
        const { match, dispatch } = this.props;
        dispatch(submitFeedback(
            match.params.feedbackUuid,
            this.state.text,
            this.state.selectedCategory,
        ));
    }

    renderCategoryName = (name) => {
        const translation = translate(`pages.feedback.${name.toLowerCase()}`);
        return translation || name;
    }

    render() {
        const { selectedCategory, text } = this.state;
        const {
            categories,
            name,
            date,
            failure,
            loading,
            sendingData,
            dataSubmitSuccess,
            dataSubmitFailure,
        } = this.props;

        if (loading || failure) {
            return (
                <div className="feedback">
                    <div className="feedback-inner">
                        <h1 className="feedback-title">
                            { failure ? translate('common.error') : `${translate('common.loading')}...` }
                        </h1>
                        { failure ? (
                            <h2 className="feedback-buttons-title">{translate('pages.feedback.getDataFailure')}</h2>
                        ) : (<span />)
                        }
                    </div>
                </div>
            );
        }

        return (
            <div className="feedback">
                <div className="feedback-inner">
                    <h1 className="feedback-title">
                        {`${name},`}<br />
                        {`${translate('pages.feedback.feedbackComplaintPart1')} ${convertDateToDateYearCompleteString(date)} 
                        ${translate('pages.feedback.feedbackComplaintPart2')}
                        ${translate('pages.feedback.wouldYouLikeToTellUseMore')}`}
                    </h1>
                    <p className="feedback-example">
                        {translate('pages.feedback.giveReason')}
                    </p>
                    <h2 className="feedback-buttons-title">{translate('pages.feedback.whatCategory')}</h2>
                    <form>
                        <div className="feedback-buttons">
                            { categories.map((category) => {
                                const active = selectedCategory && (
                                    category.id === selectedCategory.id
                                );
                                const activeClass = active ? 'feedback-button-category--active' : '';
                                return (
                                    <button key={category.id} onClick={() => this.setState({ selectedCategory: category })} type="button" className={`feedback-button-category ${activeClass}`}>
                                        { this.renderCategoryName(category.name) }
                                    </button>
                                );
                            })}
                        </div>
                        <textarea onChange={e => this.setState({ text: e.currentTarget.value })} className="feedback-textarea" rows="6" cols="50" placeholder={translate('pages.feedback.describeYourExperience')}>
                            {text}
                        </textarea>
                        { !(dataSubmitSuccess && dataSubmitFailure) ? (
                            <button
                                className={`btn btn-orange feedback-button-submit ${sendingData && 'feedback-button-submit--sending'}`}
                                disabled={!(selectedCategory && text)}
                                type="button"
                                onClick={this.onSubmit}
                            >
                                {translate('pages.feedback.sendFeedback')}
                            </button>
                        ) : (
                            <div>{ dataSubmitSuccess ? translate('pages.feedback.thankYouForTheFeedback') : translate('pages.feedback.somethingHasGoneWrong') }</div>
                        )}
                    </form>
                </div>
            </div>
        );
    }
}

Feedback.propTypes = {
    categories: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    failure: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    sendingData: PropTypes.bool.isRequired,
    dataSubmitSuccess: PropTypes.bool.isRequired,
    dataSubmitFailure: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    categories: state.feedbackReducer.categories,
    name: state.feedbackReducer.name,
    date: state.feedbackReducer.date,
    failure: state.feedbackReducer.failure,
    loading: state.feedbackReducer.loading,
    sendingData: state.feedbackReducer.sendingData,
    dataSubmitSuccess: state.feedbackReducer.dataSubmitSuccess,
    dataSubmitFailure: state.feedbackReducer.dataSubmitSuccess,
});

export default withRouter(connect(mapStateToProps)(Feedback));
