import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { getAbsencesApiCall } from '../../entities/Absence/AbsenceService';
import { TypedDispatch } from '../store';
import { setIsLoading, setAbsences, setError } from './absencesReducer';

export const getAbsences = (year: number) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const response = await getAbsencesApiCall(year);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setIsLoading(false));

            return;
        }

        dispatch(setAbsences(response.data));
    } catch (error) {
        console.error('[fetchAbsences]', error);

        dispatch(setError('Error fetching data'));
    } finally {
        dispatch(setIsLoading(false));
    }
};
