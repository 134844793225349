import React, { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedLeaveRequestForm } from '../../connectors';

const LeaveRequestPage: FC = (): ReactElement => (
    <Page className="leave-request-page">
        <ConnectedLeaveRequestForm />
    </Page>
);

export default LeaveRequestPage;
