import React, { FC } from 'react';

import DocumentListItem from '../../components/Document/DocumentListItem';
import { UserDocumentViewModel } from '../../models';
import ZeroState from '../../@paco/components/ZeroState/ZeroState';
import { translate } from '../../helpers/translations/translator';
import { ConnectedYearNavigator } from '../../@paco/connectors';
import { YearEndStatement } from '../../@paco/entities/YearEndStatement/YearEndStatement';
import { ListGroupItem, LoadingSpinner } from '../../@paco/components';
import trans from '../../@paco/helpers/trans';

import './Documents.scss';

interface DocumentsProps {
    isLoading: boolean;
    documents: UserDocumentViewModel[];
    selectedYearEndStatement?: YearEndStatement;
    onDownloadYearEndStatementClick: (year: number) => void;
    onChangeYear: (year: number) => void;
}

const Documents: FC<DocumentsProps> = ({
    isLoading,
    documents = [],
    selectedYearEndStatement,
    onDownloadYearEndStatementClick,
    onChangeYear,
}) => (
    <>
        <ConnectedYearNavigator onChangeYear={onChangeYear} />
        {isLoading && <LoadingSpinner />}
        {documents.length === 0 && !selectedYearEndStatement && <ZeroState text={translate('pages.documents.noDocumentsFound')} />}
        {selectedYearEndStatement && (
            <ul>
                <ListGroupItem
                    key={selectedYearEndStatement.employment.id}
                    onClick={() => onDownloadYearEndStatementClick(selectedYearEndStatement.year)}
                    className="documents__list-item"
                >
                    <button type="button" className="document-list-item list-group-item__header text w-100">
                        <div className="d-flex">
                            <div className="font-weight-bold">{trans('common.yearEndStatement', { year: selectedYearEndStatement.year })}</div>
                        </div>
                    </button>
                </ListGroupItem>
            </ul>
        )}
        {documents.length > 0 && (
            <ul>
                {documents.map(document => (
                    <DocumentListItem
                        key={document.id}
                        document={document}
                        className="documents__list-item"
                    />
                ))}
            </ul>
        )}
    </>
);

export default Documents;
