import React, { FC } from 'react';

import './ZeroState.scss';

interface ZeroStateProps {
    text: string;
    className?: string;
}

const ZeroState: FC<ZeroStateProps> = ({ text, className = '' }) => (
    <div className={`zero-state ${className}`}>
        <p>{text}</p>
    </div>
);

export default ZeroState;
