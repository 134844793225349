import {
    EXCHANGE_SHIFTS_FAILURE,
    EXCHANGE_SHIFTS_REQUEST,
    EXCHANGE_SHIFTS_OPEN_SUCCESS,
    EXCHANGE_SHIFTS_PLANNED_SUCCESS,
    EXCHANGE_SUCCESS,
    EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGES_SUCCESS,
    EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGE_SUCCESS,
    EXCHANGE_SHIFTS_SET_TAB,
    APP_LOGOUT,
    EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGE_REQUEST,
    EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGES_REQUEST,
    EXCHANGE_SHIFTS_CANCEL_SHIFT_EXCHANGE_REQUEST,
    EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGES_FAILURE,
    EXCHANGE_SHIFTS_CANCEL_SHIFT_EXCHANGE_FAILURE,
    EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGE_FAILURE,
    EXCHANGE_SHIFTS_CANCEL_SHIFT_EXCHANGE_SUCCESS,
    EXCHANGE_SHIFTS_SUBMIT_SHIFT_EXCHANGE_REQUEST,
    EXCHANGE_SHIFTS_SUBMIT_SHIFT_EXCHANGE_SUCCESS,
    EXCHANGE_SHIFTS_SUBMIT_SHIFT_EXCHANGE_FAILURE,
    EXCHANGE_SHIFTS_ACCEPT_SHIFT_EXCHANGE_REQUEST,
    EXCHANGE_SHIFTS_ACCEPT_SHIFT_EXCHANGE_SUCCESS,
    EXCHANGE_SHIFTS_ACCEPT_SHIFT_EXCHANGE_FAILURE,
} from '../actionTypes';

const initialState = {
    activeTab: null,
    shiftsOfferedByMe: [],
    shiftsOfferedForMe: [],
    shiftExchanges: [],
    exchange: null,
    shiftExchange: null,
    loading: false,
    errors: [],
};

const exchangesReducer = (state = initialState, action = null) => {
    switch (action.type) {
    case EXCHANGE_SHIFTS_REQUEST:
    case EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGES_REQUEST:
    case EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGE_REQUEST:
    case EXCHANGE_SHIFTS_CANCEL_SHIFT_EXCHANGE_REQUEST:
    case EXCHANGE_SHIFTS_SUBMIT_SHIFT_EXCHANGE_REQUEST:
    case EXCHANGE_SHIFTS_ACCEPT_SHIFT_EXCHANGE_REQUEST:
        return { ...state, loading: true };
    case EXCHANGE_SHIFTS_PLANNED_SUCCESS:
        return {
            ...state, loading: false, shiftsOfferedByMe: action.shiftsOfferedByMe,
        };
    case EXCHANGE_SHIFTS_OPEN_SUCCESS:
        return {
            ...state, loading: false, shiftsOfferedForMe: action.shiftsOfferedForMe,
        };
    case EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGES_SUCCESS:
        return {
            ...state, shiftExchanges: action.shiftExchanges, loading: false,
        }
    case EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGE_SUCCESS:
        return {
            ...state, shiftExchange: action.shiftExchange, loading: false,
        }
    case EXCHANGE_SHIFTS_CANCEL_SHIFT_EXCHANGE_SUCCESS:
    case EXCHANGE_SHIFTS_SUBMIT_SHIFT_EXCHANGE_SUCCESS:
    case EXCHANGE_SHIFTS_ACCEPT_SHIFT_EXCHANGE_SUCCESS:
        return { ...state, loading: false }
    case EXCHANGE_SHIFTS_FAILURE:
    case EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGE_FAILURE:
    case EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGES_FAILURE:
    case EXCHANGE_SHIFTS_CANCEL_SHIFT_EXCHANGE_FAILURE:
    case EXCHANGE_SHIFTS_SUBMIT_SHIFT_EXCHANGE_FAILURE:
    case EXCHANGE_SHIFTS_ACCEPT_SHIFT_EXCHANGE_FAILURE:
        return {
            ...state, loading: false, errors: action.errors,
        };
    case EXCHANGE_SUCCESS:
        return {
            ...state, loading: false, exchange: action.exchange,
        };
    case EXCHANGE_SHIFTS_SET_TAB:
        return {
            ...state, activeTab: action.tab,
        };
    case APP_LOGOUT:
        return { ...initialState };
    default:
        return state;
    }
};

export default exchangesReducer;
