import React, { FC, ReactElement, useEffect } from 'react';

import { LeaveOfAbsenceContainer } from '../../containers';
import { getLeaveOfAbsences } from '../../redux/leaveOfAbsencesRequests/leaveOfAbsencesRequestsActions';

import { useTypedDispatch, useTypedSelector } from '../../redux/store';

const ConnectedLeaveOfAbsences: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const { leaveOfAbsencesRequests, isLoading, isSuccessful: isAddedSuccessful } = useTypedSelector(state => state.pacoLeaveOfAbsencesRequestsReducer);
    const { isSuccessful: isDeletedSuccessful } = useTypedSelector(state => state.pacoLeaveOfAbsenceRequestDetailReducer);
    const { year } = useTypedSelector(state => state.calendarReducer);

    useEffect((): void => {
        dispatch(getLeaveOfAbsences(year));
    }, [year, isDeletedSuccessful, isAddedSuccessful]);

    return (
        <LeaveOfAbsenceContainer
            leaveOfAbsences={leaveOfAbsencesRequests}
            loading={isLoading}
        />
    );
};

export default ConnectedLeaveOfAbsences;
