import React, { FC, ReactElement } from 'react';

import { ConnectedAbsences } from '../../connectors';

import { Page } from '../../components';

const AbsencesPage: FC = (): ReactElement => (
    <Page className="absences-page">
        <ConnectedAbsences />
    </Page>
)

export default AbsencesPage;

