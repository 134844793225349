import UpToAndIncludingDate from '../../helpers/UpToAndIncludingDate';
import { LeaveOfAbsenceHoursResource, LeaveOfAbsenceHoursViewModel } from '../LeaveOfAbsenceHours';
import { Resource } from '../Resource';

export enum LeaveOfAbsenceStatus {
    open = 'open',
    approved = 'approved',
    denied = 'denied',
}

export interface LeaveOfAbsenceResource extends Resource {
    type: 'leaveOfAbsences';
    status: 'open' | 'approved' | 'denied';
    reason: string;
    declineReason: string;
    start: Date;
    end: Date;
    leaveOfAbsenceHours?: LeaveOfAbsenceHoursResource[];
    createdAt: string;
}

export interface LeaveOfAbsenceViewModel {
    id: string;
    status: LeaveOfAbsenceStatus;
    reason: string;
    declineReason: string;
    start: Date;
    end: UpToAndIncludingDate;
    leaveOfAbsenceHours: LeaveOfAbsenceHoursViewModel[];
    createdAt: Date;
}
