import { put, takeLatest } from 'redux-saga/effects';
import { ABSENCES_SET_TAB, ABSENCES_SAGA_SET_TAB } from '../actionTypes';
import { translate } from '../../helpers/translations/translator';
import { ABSENCES } from '../../constants';

export function* setAbsencesTab({ tab, history }) {
    const tabLink = tab === ABSENCES ? translate('nav.absences.absences.link') : translate('nav.absences.leaveOfAbsences.link');
    const link = `/${translate('nav.absences.link')}/${tabLink}`;
    history && history.push(link);
    yield put({ type: ABSENCES_SET_TAB, tab });
}

export default function* leaveOfAbsencesWatcher() {
    yield takeLatest(ABSENCES_SAGA_SET_TAB, setAbsencesTab);
}
