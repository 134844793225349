import React, { FC } from 'react';

import trans from '../../../../helpers/trans';
import { LeaveOfAbsence } from '../../../../entities/LeaveOfAbsences/LeaveOfAbsences';
import { InfoListItem } from '../../../../components';
import { leaveOfAbsenceStatus, LeaveOfAbsenceStatusTypes } from '../../../../types/LeaveOfAbsenceStatusTypes';
import { getLeaveOfAbsencesDateLabelShort } from '../../../../entities/LeaveOfAbsences/LeaveOfAbsencesHelpers';

import './LeaveOfAbsenceDetailList.scss'

interface LeaveOfAbsenceDetailListProps {
    leaveOfAbsence?: LeaveOfAbsence;
    className?: string;
}

const LeaveOfAbsenceDetailList: FC<LeaveOfAbsenceDetailListProps> = ({
    leaveOfAbsence,
    className = '',
}) => {
    const status = leaveOfAbsence ? leaveOfAbsence.status : {} as LeaveOfAbsenceStatusTypes;
    const { label } = leaveOfAbsenceStatus[status];

    return (
        <ul className={`leave-of-absence-detail-list ${className}`}>
            <InfoListItem
                label={trans('containers.leaveOfAbsenceDetailContainer.leaveOfAbsenceRequestedOn')}
                className="leave-of-absence-detail-list__item"
            >
                {getLeaveOfAbsencesDateLabelShort(leaveOfAbsence?.createdAt)}
            </InfoListItem>

            <InfoListItem
                label={trans('containers.leaveOfAbsenceDetailContainer.leaveOfAbsenceStartDate')}
                className="leave-of-absence-detail-list__item"
            >
                {getLeaveOfAbsencesDateLabelShort(leaveOfAbsence?.period.start)}
            </InfoListItem>

            <InfoListItem
                label={trans('containers.leaveOfAbsenceDetailContainer.leaveOfAbsenceEndDate')}
                className="leave-of-absence-detail-list__item"
            >
                {getLeaveOfAbsencesDateLabelShort(leaveOfAbsence?.period.end)}
            </InfoListItem>

            <InfoListItem
                label={trans('containers.leaveOfAbsenceDetailContainer.leaveOfAbsenceReason')}
                className="leave-of-absence-detail-list__item"
            >
                {leaveOfAbsence?.reason}
            </InfoListItem>

            <InfoListItem
                label={trans('containers.leaveOfAbsenceDetailContainer.leaveOfAbsenceStatus')}
                className="leave-of-absence-detail-list__item"
            >
                {label}
            </InfoListItem>
        </ul>
    )
};

export default LeaveOfAbsenceDetailList;
