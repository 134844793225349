import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { translate } from '../helpers/translations/translator';
import { hardRefresh } from '../helpers/tools';
import { logout } from '../redux/app/appActions';
import SVG403 from '../images/403.svg';
import SVG404 from '../images/404.svg';
import SVG500 from '../images/500.svg';

function ErrorPage({
    errorCode,
    fullHeight,
    dispatch,
}) {
    const errors = {
        403: {
            image: SVG403,
            title: translate('pages.errors.oops'),
            body: translate('pages.errors.403'),
            logout: true,
        },
        404: {
            image: SVG404,
            title: translate('pages.errors.oops'),
            body: translate('pages.errors.404'),
        },
        500: {
            image: SVG500,
            title: translate('pages.errors.oops'),
            body: translate('pages.errors.500'),
        },
    };

    const error = errors[errorCode];
    const {
        image, title, body,
    } = error;

    return (
        <div
            className={`
                full-height-page
                ${fullHeight ? 'full-height-page--no-nav' : ''}
                bg-primary
                d-flex
                justify-content-center
                align-items-center
            `}
        >
            <div className="text-white text-center px-3">
                <img className="mb-5 w-25" src={image} alt="Page not found" />

                <h5 className="text-white mb-4">
                    {title}
                </h5>

                <p className="mb-5">
                    {body}
                </p>

                <button
                    className="btn btn-orange"
                    type="button"
                    onClick={() => { error.logout ? dispatch(logout()) : hardRefresh(); }}
                >
                    {error.logout ? translate('common.logout') : translate('common.refresh')}
                </button>
            </div>
        </div>
    );
}

ErrorPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    errorCode: PropTypes.number.isRequired,
    fullHeight: PropTypes.bool,
};

ErrorPage.defaultProps = {
    fullHeight: null,
};

export default withRouter(connect()(ErrorPage));
