import { formatDate } from '../../helpers/date';
import { DateFormat } from '../../types/dateFormatTypes';
import { translate } from '../../../helpers/translations/translator';

export const getLeaveOfAbsencesDateLabel = (start: Date, end?: Date): string => {
    const formattedStartDate = formatDate(start, DateFormat.dateWithStandAloneMonthShort);

    if (!end) {
        return `${translate('pages.absences.from')} ${formattedStartDate}`;
    }

    const formattedEndDate = formatDate(end, DateFormat.dateWithStandAloneMonthShort);

    if (formattedStartDate === formattedEndDate) {
        return formattedStartDate;
    }

    return `${formattedStartDate} ${translate('pages.absences.until')} ${formattedEndDate}`;
};

export const getLeaveOfAbsencesDateLabelShort = (date?: Date): string => {
    const formattedDate = formatDate(date!, DateFormat.dateWithStandAloneMonthShort);

    return formattedDate;
};
