import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { LeaveOfAbsence } from '../../entities/LeaveOfAbsences/LeaveOfAbsences'
import { LeaveOfAbsenceList } from './subcomponents';
import { LoadingSpinner, ZeroState } from '../../components';
import { IconButton } from '../../compositions';
import { ConnectedYearNavigator } from '../../connectors';
import trans from '../../helpers/trans';

import './LeaveOfAbsenceContainer.scss';

interface LeaveOfAbsenceContainerProps {
    leaveOfAbsences: LeaveOfAbsence[];
    loading?: boolean;
    className?: string;
}

const LeaveOfAbsenceContainer: FC<LeaveOfAbsenceContainerProps> = ({
    leaveOfAbsences,
    loading,
    className,
}) => (
    <div className={`leave-of-absence ${className}`}>
        {loading && (
            <LoadingSpinner />
        )}

        <ConnectedYearNavigator
            className="leave-of-absence__year-navigator"
        />

        {!loading && !leaveOfAbsences.length ? (
            <ZeroState
                text={trans('containers.leaveOfAbsenceContainer.noLeaveOfAbsencesFound')}
                className="leave-of-absence__zero-state"
            />
        ) : (
            <LeaveOfAbsenceList
                leaveOfAbsences={leaveOfAbsences}
                className="leave-of-absence__list"
            />
        )}

        <div className="leave-of-absence__button-wrapper">
            <NavLink
                to={`/${trans('nav.absences.link')}/${trans('nav.createLeaveOfAbsence.link')}`}
                className="leave-of-absence__add-button"
            >
                <IconButton
                    hideLabel
                    icon="plus"
                    text="common.add"
                    className="leave-of-absence__add-button"
                />
            </NavLink>
        </div>
    </div>
);

export default LeaveOfAbsenceContainer;
