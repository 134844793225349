import React, { FC, ReactElement, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTypedSelector, useTypedDispatch } from '../../redux/store';

import { LeaveOfAbsenceDetailContainer } from '../../containers';
import { fetchLeaveOfAbsencesDetail, cancelLeaveOfAbsence } from '../../redux/leaveOfAbsenceRequestDetail/leaveOfAbsenceRequestDetailActions';
import trans from '../../helpers/trans';

const ConnectedLeaveOfAbsenceDetail: FC = (): ReactElement => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const dispatch = useTypedDispatch();

    const { leaveOfAbsenceRequestDetail, isLoading } = useTypedSelector(state => state.pacoLeaveOfAbsenceRequestDetailReducer);

    const cancelLeaveOfAbsenceHandler = (leaveOfAbsenceId: string) => {
        dispatch(cancelLeaveOfAbsence(leaveOfAbsenceId));

        history.push(`/${trans('nav.absences.link')}/${trans('nav.leaveOfAbsenceRequests.link')}`);
    }

    useEffect(() => {
        dispatch(fetchLeaveOfAbsencesDetail(id));
    }, [id]);

    return (
        <LeaveOfAbsenceDetailContainer
            cancelLeaveOfAbsence={() => cancelLeaveOfAbsenceHandler(id)}
            leaveOfAbsence={leaveOfAbsenceRequestDetail}
            isLoading={isLoading}
        />
    );
};

export default ConnectedLeaveOfAbsenceDetail;
