import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncReduxState, initialAsyncReduxState } from '../@config/AsyncReduxState';
import { LeaveOfAbsence } from '../../entities/LeaveOfAbsences/LeaveOfAbsences';

export type LeaveOfAbsenceRequestDetailState = AsyncReduxState< {
    leaveOfAbsenceRequestDetail?: LeaveOfAbsence;
}>;

const initialState: LeaveOfAbsenceRequestDetailState = {
    ...initialAsyncReduxState,
};

const leaveOfAbsenceRequestDetailSlice = createSlice({
    name: 'pacoLeaveOfAbsenceRequestDetailReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): LeaveOfAbsenceRequestDetailState {
            return ({
                ...state,
                isLoading: action.payload,
            });
        },
        setLeaveOfAbsenceRequestDetail(state, action: PayloadAction<LeaveOfAbsence | undefined>): LeaveOfAbsenceRequestDetailState {
            return ({
                ...state,
                leaveOfAbsenceRequestDetail: action.payload,
            });
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): LeaveOfAbsenceRequestDetailState {
            return ({
                ...state,
                isSuccessful: action.payload,
            });
        },
        setError(state, action: PayloadAction<string>): LeaveOfAbsenceRequestDetailState {
            return ({
                ...state,
                error: action.payload,
            });
        },
    },
});

export const {
    setIsLoading,
    setLeaveOfAbsenceRequestDetail,
    setIsSuccessful,
    setError,
} = leaveOfAbsenceRequestDetailSlice.actions;

export default leaveOfAbsenceRequestDetailSlice.reducer;
