import axios from 'axios';

export const getExchangesOfferedByMe = (includes = []) => axios.get(
    `${process.env.REACT_APP_API_URL}/me/exchanges/offered-by-me`,
    {
        params: {
            include: includes.join(','),
            orderBy: JSON.stringify({ start: 'ASC' }),
        },
    },
);

export const getExchangesOfferedForMe = (includes = []) => axios.get(
    `${process.env.REACT_APP_API_URL}/me/exchanges/offered-to-me`,
    {
        params: {
            include: includes.join(','),
            orderBy: JSON.stringify({ start: 'ASC' }),
        },
    },
);

export const getExchange = (includes = [], exchangeUuid = null) => axios.get(
    `${process.env.REACT_APP_API_URL}/exchanges/${exchangeUuid}`,
    {
        params: {
            include: includes.join(','),
        },
    },
);
