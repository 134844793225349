import React, { FC, ReactElement, useMemo } from 'react';

import { Absence } from '../../../../entities/Absence/Absence';
import { ListGroupItem } from '../../../../components';
import trans from '../../../../helpers/trans';
import { getAbsenceDateLabel } from '../../../../entities/Absence/AbsenceHelpers';

import './AbsenceListItem.scss';

interface AbsenceListItemProps {
    absence: Absence;
    className?: string;
}

const AbsenceListItem: FC<AbsenceListItemProps> = ({ absence, className }): ReactElement => {
    const text = useMemo(() => getAbsenceDateLabel(absence.period), [absence]);

    return (
        <ListGroupItem className={`absence-list-item ${className}`}>
            <div className="absence-list-item__wrapper">
                <p className="absence-list-item__label">{trans('containers.absencesContainer.absence')}</p>
                <p className="absence-list-item__date">{text}</p>
            </div>
        </ListGroupItem>
    )
}

export default AbsenceListItem;
