import { CardColor } from './cardColor';
import trans from '../helpers/trans';

export enum LeaveOfAbsenceStatusTypes {
    open = 'open',
    approved = 'approved',
    denied = 'denied',
}

export const leaveOfAbsenceStatus: Record<LeaveOfAbsenceStatusTypes, { label: string; color: CardColor }> = {
    [LeaveOfAbsenceStatusTypes.open]: { label: trans('common.inProgress'), color: CardColor.blue },
    [LeaveOfAbsenceStatusTypes.approved]: { label: trans('common.approved'), color: CardColor.primary },
    [LeaveOfAbsenceStatusTypes.denied]: { label: trans('common.denied'), color: CardColor.grey },
};
