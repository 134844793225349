import React, { FC, ReactElement } from 'react';

import { AbsenceListItem } from '../index';
import { Absence } from '../../../../entities/Absence/Absence';

interface AbsencesListProps {
    absences: Absence[];
    className?: string;
}

const AbsencesList: FC<AbsencesListProps> = ({ absences, className }): ReactElement => (
    <ul className={`absences-list ${className}`}>
        {absences.map((absence) => (
            <AbsenceListItem
                key={absence.id}
                absence={absence}
                className="absences-list__item"
            />
        ))}
    </ul>
);

export default AbsencesList;
