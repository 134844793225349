import React, { FC } from 'react';

import trans from '../../helpers/trans';

import { LoadingSpinner, ZeroState } from '../../components';
import { ConnectedYearNavigator } from '../../connectors';
import { AbsencesList } from './subcomponents/index';
import { Absence } from '../../entities/Absence/Absence';

import './AbsencesContainer.scss';

interface AbsencesContainerProps {
    absences: Absence[];
    loading: boolean;
}

const AbsencesContainer: FC<AbsencesContainerProps> = ({
    absences,
    loading,
}) => (
    <div className="absences">
        {loading && (
            <LoadingSpinner />
        )}

        <ConnectedYearNavigator
            className="absences__year-navigator"
        />

        {!loading && !absences.length ? (
            <ZeroState
                text={trans('containers.absencesContainer.noAbsencesFound')}
                className="absences__zero-state"
            />
        ) : (
            <AbsencesList
                absences={absences}
            />
        )}
    </div>
);

export default AbsencesContainer;
