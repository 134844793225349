import { put, takeLatest } from 'redux-saga/effects';
import {
    SET_LOADING,
    DOCUMENTS_REQUEST,
    DOCUMENTS_GET_DOCUMENTS_FOR_ME_SUCCESS,
    DOCUMENTS_FAILURE,
    DOCUMENTS_SAGA_GET_DOCUMENTS_FOR_ME,
} from '../actionTypes';
import { GetDocumentsForMeAction } from './documentsModels';
import { getFilesForMe } from '../../helpers/api-ts/files';
import { getUserPayslips } from '../../helpers/api-ts/payslip';

export function* fetchDocuments({ year, user }: GetDocumentsForMeAction): unknown {
    yield put({ type: SET_LOADING, loading: true });
    yield put({ type: DOCUMENTS_REQUEST });

    try {
        const includes = ['documentType', 'linkedUser'];
        const files = yield getFilesForMe(year, includes);

        const { loketEmployeeId } = user;
        const payslips = yield loketEmployeeId ? getUserPayslips(loketEmployeeId, year) : [];

        yield put({
            type: DOCUMENTS_GET_DOCUMENTS_FOR_ME_SUCCESS,
            documents: [...files, ...payslips],
        });
    } catch (error) {
        yield put(
            { type: DOCUMENTS_FAILURE, errors: [(error as { response: unknown }).response] },
        );
    }

    yield put({ type: SET_LOADING, loading: false });
}

export default function* documentsWatcher() {
    // @ts-ignore
    yield takeLatest(DOCUMENTS_SAGA_GET_DOCUMENTS_FOR_ME, fetchDocuments);
}
