import {
    SET_LOADING,
    SET_APP_ERROR,
    APP_LOGOUT,
    AUTH_AUTHENTICATE_USER_SUCCESS,
    APP_GET_PAYROLL_PERIODS_SUCCESS,
    APP_GET_PAYROLL_PERIODS_FAILURE,
} from '../actionTypes';

const initialState = {
    loading: false,
    error: null,
    toasts: [],
    payrollPeriods: [],
};

const appReducer = (state = initialState, action = null) => {
    switch (action.type) {
    case SET_LOADING:
        return { ...state, loading: action.loading };
    case SET_APP_ERROR:
    case APP_GET_PAYROLL_PERIODS_FAILURE:
        return { ...state, error: action.error };
    case APP_GET_PAYROLL_PERIODS_SUCCESS:
        return { ...state, payrollPeriods: action.payrollPeriods };
    case AUTH_AUTHENTICATE_USER_SUCCESS:
        return { ...state, toasts: [] };
    case APP_LOGOUT:
        return { ...initialState };
    default:
        return state;
    }
};

export default appReducer;
