import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { LeaveOfAbsenceForm } from '../../entities/LeaveOfAbsences/LeaveOfAbsences';
import { createLeaveOfAbsenceApiCall, getLeaveOfAbsencesApiCall } from '../../entities/LeaveOfAbsences/LeaveOfAbsencesService';
import { TypedDispatch } from '../store';
import { setIsSuccessful as leaveOfAbsenceIsDeletedSuccessful } from '../leaveOfAbsenceRequestDetail/leaveOfAbsenceRequestDetailReducer';
import { setToast } from '../toasts/toastsReducer';
import { ToastType } from '../../types/toastTypes';
import trans from '../../helpers/trans';
import {
    setIsLoading,
    setIsSuccessful,
    setLeaveOfAbsencesRequest,
    setError,
} from './leaveOfAbsencesRequestsReducer';

export const getLeaveOfAbsences = (year: number) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const response = await getLeaveOfAbsencesApiCall(year);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setIsLoading(false));

            return;
        }

        dispatch(setLeaveOfAbsencesRequest(response.data));
        dispatch(leaveOfAbsenceIsDeletedSuccessful(false));
        dispatch(setIsSuccessful(false));
    } catch (error) {
        console.error('[getLeaveOfAbsences]', error);

        dispatch(setError('Error fetching data'));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const addLeaveOfAbsence = (leaveOfAbsence: LeaveOfAbsenceForm) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));

    try {
        const response = await createLeaveOfAbsenceApiCall(leaveOfAbsence);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setIsLoading(false));

            return;
        }

        dispatch(setToast({
            text: trans('containers.leaveOfAbsenceDetailContainer.leaveOfAbsenceRequest'),
            type: ToastType.pass,
        }));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[addLeaveOfAbsence]', error);

        dispatch(setError('Error fetching data'));
    } finally {
        dispatch(setIsLoading(false));
    }
};
