import { compareAsc } from 'date-fns';

export const customCompareLeaveRequestDate = (startDates: Date, endDates: Date) => {
    const start = new Date(startDates);
    const end = new Date(endDates);

    if (start.getTime() === end.getTime()) {
        return -1;
    }

    return compareAsc(start, end);
};
