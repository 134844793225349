import { authorizedFetch } from '../../helpers/authorizedFetch';
import { Absence, AbsenceResource } from './Absence';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import isResourceCollectionDocument from '../../japi/guards/isResourceCollectionDocument';
import { FetchResultType, FetchResult } from '../FetchResult';
import { transformToAbsence } from './AbsenceTransformers';

let getAbsencesApiCallAbortController = new AbortController();

export const getAbsencesApiCall = async (year: number): Promise<FetchResult<Absence[], string>> => {
    getAbsencesApiCallAbortController.abort();
    getAbsencesApiCallAbortController = new AbortController();

    try {
        const url = generateApiUrl({
            endpoint: '/me/absences',

            queryParams: {
                startDate: `${year}-01-01`,
                endDate: `${year}-12-31`,
            },
        });

        const response = await authorizedFetch(url, {
            signal: getAbsencesApiCallAbortController.signal,
        });

        const doc = await response.json();

        if (!isResourceCollectionDocument<AbsenceResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: doc.data.map(transformToAbsence),
        };
    } catch (error) {
        console.error('[getAbsencesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
