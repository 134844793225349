import { authorizedFetch } from '../../helpers/authorizedFetch';
import { LeaveOfAbsence, LeaveOfAbsenceForm, LeaveOfAbsenceResource } from './LeaveOfAbsences';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import isResourceCollectionDocument from '../../japi/guards/isResourceCollectionDocument';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { FetchResultType, FetchCollectionResult, FetchResult } from '../FetchResult';
import { transformLeaveOfAbsences, transformLeaveOfAbsencesDetailToFormDataApiParams } from './LeaveOfAbsencesTransformers';

let getLeaveOfAbsencesApiCallAbortController = new AbortController();

export const getLeaveOfAbsencesApiCall = async (year: number): Promise<FetchCollectionResult<LeaveOfAbsence[], string>> => {
    getLeaveOfAbsencesApiCallAbortController.abort();
    getLeaveOfAbsencesApiCallAbortController = new AbortController();

    try {
        const url = generateApiUrl({
            endpoint: '/me/leave-of-absences',

            queryParams: {
                startDate: `${year}-01-01`,
                endDate: `${year}-12-31`,
            },
        });

        const response = await authorizedFetch(url, {
            signal: getLeaveOfAbsencesApiCallAbortController.signal,
        });

        const doc = await response.json();

        if (!isResourceCollectionDocument<LeaveOfAbsenceResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const sortedData = doc.data
            .map(transformLeaveOfAbsences)
            .sort((a, b) => a.period.start.getTime() - b.period.end.getTime());

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: sortedData,
        };
    } catch (error) {
        console.error('[getLeaveOfAbsencesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getLeaveOfAbsencesDetailApiCall = async (id: string): Promise<FetchCollectionResult<LeaveOfAbsence, string>> => {
    try {
        const url = generateApiUrl({
            endpoint: `/leave-of-absences/${id}`,
        });

        const response = await authorizedFetch(url);

        const doc = await response.json();

        if (!isResourceDocument<LeaveOfAbsenceResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: transformLeaveOfAbsences(doc.data),
        };
    } catch (error) {
        console.error('[getLeaveOfAbsencesDetailApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const createLeaveOfAbsenceApiCall = async (leaveOfAbsence: LeaveOfAbsenceForm): Promise<FetchResult<LeaveOfAbsence, string>> => {
    const apiFormData = transformLeaveOfAbsencesDetailToFormDataApiParams(leaveOfAbsence);

    try {
        const url = generateApiUrl({
            endpoint: '/me/leave-of-absences',
        });

        const response = await authorizedFetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: apiFormData }),
        });

        const doc = await response.json();

        if (!isResourceDocument<LeaveOfAbsenceResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: transformLeaveOfAbsences(doc.data),
        };
    } catch (error) {
        console.error('[createLeaveOfAbsenceApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const cancelLeaveOfAbsenceApiCall = async (id: string): Promise<FetchResult<boolean, string>> => {
    try {
        const url = generateApiUrl({
            endpoint: `/me/leave-of-absences/${id}`,
        });

        const response = await authorizedFetch(url, {
            method: 'DELETE',
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: true,
        };
    } catch (error) {
        console.error('[cancelLeaveOfAbsenceApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
