// App
export const SET_LOADING = 'SET_LOADING';
export const SET_APP_ERROR = 'SET_APP_ERROR';
export const APP_SAGA_SET_LOADING = 'APP_SAGA_SET_LOADING';
export const APP_SAGA_SET_APP_ERROR = 'APP_SAGA_SET_APP_ERROR';
export const APP_SAGA_GET_PAYROLL_PERIODS = 'APP_SAGA_GET_PAYROLL_PERIODS';
export const APP_GET_PAYROLL_PERIODS_REQUEST = 'APP_GET_PAYROLL_PERIODS_REQUEST';
export const APP_GET_PAYROLL_PERIODS_SUCCESS = 'APP_GET_PAYROLL_PERIODS_SUCCESS';
export const APP_GET_PAYROLL_PERIODS_FAILURE = 'APP_GET_PAYROLL_PERIODS_FAILURE';
export const APP_SAGA_LOGOUT = 'APP_SAGA_LOGOUT';
export const APP_LOGOUT = 'APP_LOGOUT';

// User
export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST';
export const CURRENT_USER_SUCCESS = 'CURRENT_USER_SUCCESS';
export const CURRENT_USER_FAILURE = 'CURRENT_USER_FAILURE';
export const CURRENT_USER_SAGA_GET_CURRENT_USER = 'CURRENT_USER_SAGA_GET_CURRENT_USER';
export const CURRENT_USER_SAGA_EDIT_EMAIL = 'CURRENT_USER_SAGA_EDIT_EMAIL';
export const CURRENT_USER_SAGA_EDIT_PHONE = 'CURRENT_USER_SAGA_EDIT_PHONE';
export const CURRENT_USER_SAGA_EDIT_ADDRESS = 'CURRENT_USER_SAGA_EDIT_ADDRESS';
export const CURRENT_USER_SAGA_EDIT_EMERGENCY_CONTACT = 'CURRENT_USER_SAGA_EDIT_EMERGENCY_CONTACT';
export const CURRENT_USER_SAGA_EDIT_GENDER = 'CURRENT_USER_SAGA_EDIT_GENDER';
export const CURRENT_USER_EDIT_REQUEST = 'CURRENT_USER_EDIT_REQUEST';
export const CURRENT_USER_EDIT_SUCCESS = 'CURRENT_USER_EDIT_SUCCESS';
export const CURRENT_USER_EDIT_FAILURE = 'CURRENT_USER_EDIT_FAILURE';

// Authentication
export const AUTH_SET_TENANT = 'AUTH_SET_TENANT';
export const AUTH_SET_API_TOKEN = 'AUTH_SET_API_TOKEN';
export const AUTH_SET_REFRESH_TOKEN = 'AUTH_SET_REFRESH_TOKEN';
export const AUTH_SAGA_UNSET_TOKENS = 'AUTH_SAGA_UNSET_TOKENS';
export const AUTH_UNSET_TOKENS = 'AUTH_UNSET_TOKENS';
export const AUTH_SAGA_SET_API_TOKEN_AND_GET_USER = 'AUTH_SAGA_SET_API_TOKEN_AND_GET_USER';
export const AUTH_SET_API_TOKEN_AND_GET_USER_REQUEST = 'AUTH_SET_API_TOKEN_AND_GET_USER_REQUEST';
export const AUTH_SET_API_TOKEN_AND_GET_USER_SUCCESS = 'AUTH_SET_API_TOKEN_AND_GET_USER_SUCCESS';
export const AUTH_SET_API_TOKEN_AND_GET_USER_FAILURE = 'AUTH_SET_API_TOKEN_AND_GET_USER_FAILURE';
export const AUTH_SAGA_AUTHENTICATE_USER = 'AUTH_SAGA_AUTHENTICATE_USER';
export const AUTH_AUTHENTICATE_USER_REQUEST = 'AUTH_AUTHENTICATE_USER_REQUEST';
export const AUTH_AUTHENTICATE_USER_SUCCESS = 'AUTH_AUTHENTICATE_USER_SUCCESS';
export const AUTH_AUTHENTICATE_USER_FAILURE = 'AUTH_AUTHENTICATE_USER_FAILURE';
export const AUTH_SAGA_UPDATE_API_TOKEN = 'AUTH_SAGA_UPDATE_API_TOKEN';
export const AUTH_SAGA_UPDATE_REFRESH_TOKEN = 'AUTH_SAGA_UPDATE_REFRESH_TOKEN';
export const AUTH_SAGA_UPDATE_TENANT = 'AUTH_SAGA_UPDATE_TENANT';
export const AUTH_SAGA_SUBMIT_PASSWORD = 'AUTH_SAGA_SUBMIT_PASSWORD';
export const AUTH_SUBMIT_PASSWORD_REQUEST = 'AUTH_SUBMIT_PASSWORD_REQUEST';
export const AUTH_SUBMIT_PASSWORD_SUCCESS = 'AUTH_SUBMIT_PASSWORD_SUCCESS';
export const AUTH_SUBMIT_PASSWORD_FAILURE = 'AUTH_SUBMIT_PASSWORD_FAILURE';
export const AUTH_SAGA_FORGOT_PASSWORD = 'AUTH_SAGA_FORGOT_PASSWORD';
export const AUTH_FORGOT_PASSWORD_REQUEST = 'AUTH_FORGOT_PASSWORD_REQUEST';
export const AUTH_FORGOT_PASSWORD_SUCCESS = 'AUTH_FORGOT_PASSWORD_SUCCESS';
export const AUTH_FORGOT_PASSWORD_FAILURE = 'AUTH_FORGOT_PASSWORD_FAILURE';

// Tracks
export const TRACKS_REQUEST = 'TRACKS_REQUEST';
export const TRACKS_SUCCESS = 'TRACKS_SUCCESS';
export const TRACKS_FAILURE = 'TRACKS_FAILURE';
export const TRACKS_SAGA_GET_TRACKS = 'TRACKS_SAGA_GET_TRACKS';

// Track
export const TRACK_REQUEST = 'TRACK_REQUEST';
export const TRACK_SUCCESS = 'TRACK_SUCCESS';
export const TRACK_FAILURE = 'TRACK_FAILURE';
export const TRACK_SAGA_GET_TRACK = 'TRACKS_SAGA_GET_TRACK';

// Shifts
export const SHIFTS_REQUEST = 'SHIFTS_REQUEST';
export const SHIFTS_FAILURE = 'SHIFTS_FAILURE';
export const SHIFTS_PLANNED_SUCCESS = 'SHIFTS_PLANNED_SUCCESS';
export const SHIFTS_OPEN_SUCCESS = 'SHIFTS_OPEN_SUCCESS';
export const SHIFTS_SAGA_GET_PLANNED_SHIFTS = 'SHIFTS_SAGA_GET_PLANNED_SHIFTS';
export const SHIFTS_SAGA_GET_OPEN_SHIFTS = 'SHIFTS_SAGA_GET_OPEN_SHIFTS';
export const SHIFTS_SAGA_SET_TAB = 'SHIFTS_SAGA_SET_TAB';
export const SHIFTS_SET_TAB = 'SHIFTS_SET_TAB';

// ExchangeShifts
export const EXCHANGE_SHIFTS_REQUEST = 'EXCHANGE_SHIFTS_REQUEST';
export const EXCHANGE_SHIFTS_FAILURE = 'EXCHANGE_SHIFTS_FAILURE';
export const EXCHANGE_SHIFTS_PLANNED_SUCCESS = 'EXCHANGE_SHIFTS_PLANNED_SUCCESS';
export const EXCHANGE_SHIFTS_OPEN_SUCCESS = 'EXCHANGE_SHIFTS_OPEN_SUCCESS';
export const EXCHANGE_SUCCESS = 'EXCHANGE_SUCCESS';
export const EXCHANGE_SHIFTS_SAGA_SET_TAB = 'EXCHANGE_SHIFTS_SAGA_SET_TAB';
export const EXCHANGE_SHIFTS_SET_TAB = 'EXCHANGE_SHIFTS_SET_TAB';
export const EXCHANGE_SHIFTS_SAGA_GET_SHIFTS_OFFERED_BY_ME = 'EXCHANGE_SHIFTS_SAGA_GET_SHIFTS_OFFERED_BY_ME';
export const EXCHANGE_SHIFTS_SAGA_GET_SHIFTS_OFFERED_FOR_ME = 'EXCHANGE_SHIFTS_SAGA_GET_SHIFTS_OFFERED_FOR_ME';
export const EXCHANGE_SHIFTS_SAGA_GET_EXCHANGE = 'EXCHANGE_SHIFTS_SAGA_GET_EXCHANGE';
export const EXCHANGE_SHIFTS_SAGA_SET_EXCHANGE = 'EXCHANGE_SHIFTS_SAGA_SET_EXCHANGE';

// ShiftExchange
export const EXCHANGE_SHIFTS_SAGA_GET_SHIFT_EXCHANGES = 'EXCHANGE_SHIFTS_SAGA_GET_SHIFT_EXCHANGES';
export const EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGES_REQUEST = 'EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGES_REQUEST';
export const EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGES_SUCCESS = 'EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGES_SUCCESS';
export const EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGES_FAILURE = 'EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGES_FAILURE';
export const EXCHANGE_SHIFTS_SAGA_GET_SHIFT_EXCHANGE = 'EXCHANGE_SHIFTS_SAGA_GET_SHIFT_EXCHANGE';
export const EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGE_REQUEST = 'EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGE_REQUEST';
export const EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGE_SUCCESS = 'EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGE_SUCCESS';
export const EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGE_FAILURE = 'EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGE_FAILURE';
export const EXCHANGE_SHIFTS_SAGA_CANCEL_SHIFT_EXCHANGE = 'EXCHANGE_SHIFTS_SAGA_CANCEL_SHIFT_EXCHANGE';
export const EXCHANGE_SHIFTS_CANCEL_SHIFT_EXCHANGE_REQUEST = 'EXCHANGE_SHIFTS_CANCEL_SHIFT_EXCHANGE_REQUEST';
export const EXCHANGE_SHIFTS_CANCEL_SHIFT_EXCHANGE_SUCCESS = 'EXCHANGE_SHIFTS_CANCEL_SHIFT_EXCHANGE_SUCCESS';
export const EXCHANGE_SHIFTS_CANCEL_SHIFT_EXCHANGE_FAILURE = 'EXCHANGE_SHIFTS_CANCEL_SHIFT_EXCHANGE_FAILURE';
export const EXCHANGE_SHIFTS_SAGA_ACCEPT_SHIFT_EXCHANGE = 'EXCHANGE_SHIFTS_SAGA_ACCEPT_SHIFT_EXCHANGE';
export const EXCHANGE_SHIFTS_ACCEPT_SHIFT_EXCHANGE_REQUEST = 'EXCHANGE_SHIFTS_ACCEPT_SHIFT_EXCHANGE_REQUEST';
export const EXCHANGE_SHIFTS_ACCEPT_SHIFT_EXCHANGE_SUCCESS = 'EXCHANGE_SHIFTS_ACCEPT_SHIFT_EXCHANGE_SUCCESS';
export const EXCHANGE_SHIFTS_ACCEPT_SHIFT_EXCHANGE_FAILURE = 'EXCHANGE_SHIFTS_ACCEPT_SHIFT_EXCHANGE_FAILURE';
export const EXCHANGE_SHIFTS_SAGA_SUBMIT_SHIFT_EXCHANGE = 'EXCHANGE_SHIFTS_SAGA_SUBMIT_SHIFT_EXCHANGE';
export const EXCHANGE_SHIFTS_SUBMIT_SHIFT_EXCHANGE_REQUEST = 'EXCHANGE_SHIFTS_SUBMIT_SHIFT_EXCHANGE_REQUEST';
export const EXCHANGE_SHIFTS_SUBMIT_SHIFT_EXCHANGE_SUCCESS = 'EXCHANGE_SHIFTS_SUBMIT_SHIFT_EXCHANGE_SUCCESS';
export const EXCHANGE_SHIFTS_SUBMIT_SHIFT_EXCHANGE_FAILURE = 'EXCHANGE_SHIFTS_SUBMIT_SHIFT_EXCHANGE_FAILURE';

// Shift
export const SHIFT_REQUEST = 'SHIFT_REQUEST';
export const SHIFT_SUCCESS = 'SHIFT_SUCCESS';
export const SHIFT_FAILURE = 'SHIFT_FAILURE';
export const SHIFT_SAGA_GET_SHIFT = 'SHIFT_SAGA_GET_SHIFT';
export const SHIFT_SAGA_REGISTER_SHIFT = 'SHIFT_SAGA_REGISTER_SHIFT';
export const SHIFT_SAGA_UNREGISTER_SHIFT = 'SHIFT_SAGA_UNREGISTER_SHIFT';
export const SHIFT_SAGA_OFFER_SHIFT = 'SHIFT_SAGA_OFFER_SHIFT';
export const SHIFT_SAGA_CANCEL_OFFER_SHIFT = 'SHIFT_SAGA_CANCEL_OFFER_SHIFT';
export const SHIFT_SAGA_REGISTER_OFFERED_SHIFT = 'SHIFT_SAGA_REGISTER_OFFERED_SHIFT';
export const SHIFT_SAGA_UNREGISTER_OFFERED_SHIFT = 'SHIFT_SAGA_UNREGISTER_OFFERED_SHIFT';
export const SHIFT_SAGA_SET_SHIFT = 'SHIFT_SAGA_SET_SHIFT';

// Feedback
export const FEEDBACK_REQUEST = 'FEEDBACK_REQUEST';
export const FEEDBACK_SUCCESS = 'FEEDBACK_SUCCESS';
export const FEEDBACK_SUBMIT = 'FEEDBACK_SUBMIT';
export const FEEDBACK_SUBMIT_SUCCESS = 'FEEDBACK_SUBMIT_SUCCESS';
export const FEEDBACK_SUBMIT_FAILURE = 'FEEDBACK_SUBMIT_FAILURE';
export const FEEDBACK_FAILURE = 'FEEDBACK_FAILURE';
export const FEEDBACK_SAGA_GET_FEEDBACK = 'FEEDBACK_SAGA_GET_FEEDBACK';
export const FEEDBACK_SAGA_GET_FEEDBACK_CATEGORIES = 'FEEDBACK_SAGA_GET_FEEDBACK_CATEGORIES';
export const FEEDBACK_SAGA_SUBMIT_FEEDBACK = 'FEEDBACK_SAGA_SUBMIT_FEEDBACK';

// Feedback Categories
export const FEEDBACK_CATEGORIES_SUCCESS = 'FEEDBACK_CATEGORIES_SUCCESS';
export const FEEDBACK_CATEGORIES_FAILURE = 'FEEDBACK_CATEGORIES_FAILURE';

// Week Navigator
export const WEEK_NAVIGATOR_SET_DATES = 'WEEK_NAVIGATOR_SET_DATES';
export const WEEK_NAVIGATOR_SAGA_SET_DATES = 'WEEK_NAVIGATOR_SAGA_SET_DATES';
export const WEEK_NAVIGATOR_SET_YEAR = 'WEEK_NAVIGATOR_SET_YEAR';
export const WEEK_NAVIGATOR_SAGA_SET_YEAR = 'WEEK_NAVIGATOR_SAGA_SET_YEAR';
export const WEEK_NAVIGATOR_SAGA_SET_PERIOD = 'WEEK_NAVIGATOR_SAGA_SET_PERIOD';
export const WEEK_NAVIGATOR_SET_PERIOD = 'WEEK_NAVIGATOR_SET_PERIOD';

// Leave of absences
export const LEAVE_OF_ABSENCES_REQUEST = 'LEAVE_OF_ABSENCES_REQUEST';
export const LEAVE_OF_ABSENCES_SUCCESS = 'LEAVE_OF_ABSENCES_SUCCESS';
export const LEAVE_OF_ABSENCES_FAILURE = 'LEAVE_OF_ABSENCES_FAILURE';

// Absences
export const ABSENCES_SAGA_SET_TAB = 'ABSENCES_SAGA_SET_TAB';
export const ABSENCES_SAGA_GET_LEAVE_OF_ABSENCES_ME = 'ABSENCES_SAGA_GET_LEAVE_OF_ABSENCES_ME';
export const ABSENCES_SAGA_GET_LEAVE_OF_ABSENCE = 'ABSENCES_SAGA_GET_LEAVE_OF_ABSENCE';
export const ABSENCES_SAGA_CREATE_LEAVE_OF_ABSENCE = 'ABSENCES_SAGA_CREATE_LEAVE_OF_ABSENCE';
export const ABSENCES_SAGA_CANCEL_LEAVE_OF_ABSENCE = 'ABSENCES_SAGA_CANCEL_LEAVE_OF_ABSENCE';
export const ABSENCES_SET_TAB = 'ABSENCES_SET_TAB';
export const ABSENCES_REQUEST = 'ABSENCES_REQUEST';
export const ABSENCES_SUCCESS = 'ABSENCES_SUCCESS';
export const ABSENCES_FAILURE = 'ABSENCES_FAILURE';
export const ABSENCES_SAGA_GET_USER_WITH_EMPLOYEE_BALANCES = 'ABSENCES_SAGA_GET_USER_WITH_EMPLOYEE_BALANCES';
export const ABSENCES_GET_USER_WITH_EMPLOYEE_BALANCES_REQUEST = 'ABSENCES_GET_USER_WITH_EMPLOYEE_BALANCES_REQUEST';
export const ABSENCES_GET_USER_WITH_EMPLOYEE_BALANCES_FAILURE = 'ABSENCES_GET_USER_WITH_EMPLOYEE_BALANCES_FAILURE';
export const ABSENCES_GET_USER_WITH_EMPLOYEE_BALANCES_SUCCESS = 'ABSENCES_GET_USER_WITH_EMPLOYEE_BALANCES_SUCCESS';
export const ABSENCES_SAGA_GET_CURRENT_PAYROLL_PERIOD = 'ABSENCES_SAGA_GET_CURRENT_PAYROLL_PERIOD';
export const ABSENCES_GET_CURRENT_PAYROLL_PERIOD_REQUEST = 'ABSENCES_GET_CURRENT_PAYROLL_PERIOD_REQUEST';
export const ABSENCES_GET_CURRENT_PAYROLL_PERIOD_SUCCESS = 'ABSENCES_GET_CURRENT_PAYROLL_PERIOD_SUCCESS';
export const ABSENCES_GET_CURRENT_PAYROLL_PERIOD_FAILURE = 'ABSENCES_GET_CURRENT_PAYROLL_PERIOD_FAILURE';
export const ABSENCES_SAGA_GET_EMPLOYEE_LOKET_BALANCES = 'ABSENCES_SAGA_GET_EMPLOYEE_LOKET_BALANCES';
export const ABSENCES_GET_EMPLOYEE_LOKET_BALANCES_REQUEST = 'ABSENCES_GET_EMPLOYEE_LOKET_BALANCES_REQUEST';
export const ABSENCES_GET_EMPLOYEE_LOKET_BALANCES_SUCCESS = 'ABSENCES_GET_EMPLOYEE_LOKET_BALANCES_SUCCESS';
export const ABSENCES_GET_EMPLOYEE_LOKET_BALANCES_FAILURE = 'ABSENCES_GET_EMPLOYEE_LOKET_BALANCES_FAILURE';

// Registration
export const REGISTRATION_SAGA_SUBMIT = 'REGISTRATION_SAGA_SUBMIT';
export const REGISTRATION_SUBMIT_REQUEST = 'REGISTRATION_SUBMIT_REQUEST';
export const REGISTRATION_SUBMIT_SUCCESS = 'REGISTRATION_SUBMIT_SUCCESS';
export const REGISTRATION_SUBMIT_FAILURE = 'REGISTRATION_SUBMIT_FAILURE';
export const REGISTRATION_SAGA_GET_DATA = 'REGISTRATION_SAGA_GET_DATA';

// Leave of absence
export const LEAVE_OF_ABSENCE_REQUEST = 'LEAVE_OF_ABSENCE_REQUEST';
export const LEAVE_OF_ABSENCE_SUCCESS = 'LEAVE_OF_ABSENCE_SUCCESS';
export const LEAVE_OF_ABSENCE_FAILURE = 'LEAVE_OF_ABSENCE_FAILURE';

// Documents
export const DOCUMENTS_SAGA_GET_DOCUMENTS_FOR_ME = 'DOCUMENTS_SAGA_GET_DOCUMENTS_FOR_ME';
export const DOCUMENTS_GET_DOCUMENTS_FOR_ME_SUCCESS = 'DOCUMENTS_GET_DOCUMENTS_FOR_ME_SUCCESS';
export const DOCUMENTS_REQUEST = 'DOCUMENTS_REQUEST';
export const DOCUMENTS_SUCCESS = 'DOCUMENTS_SUCCESS';
export const DOCUMENTS_FAILURE = 'DOCUMENTS_FAILURE';

// Preference days
export const PREFERENCE_SAGA_GET_PLANNING_PREFERENCES = 'PREFERENCE_SAGA_GET_PLANNING_PREFERENCES';
export const PREFERENCE_GET_PLANNING_PREFERENCES_REQUEST = 'PREFERENCE_GET_PLANNING_PREFERENCES_REQUEST';
export const PREFERENCE_GET_PLANNING_PREFERENCES_SUCCESS = 'PREFERENCE_GET_PLANNING_PREFERENCES_SUCCESS';
export const PREFERENCE_GET_PLANNING_PREFERENCES_FAILURE = 'PREFERENCE_GET_PLANNING_PREFERENCES_FAILURE';
export const PREFERENCE_SAGA_GET_PLANNING_PREFERENCE = 'PREFERENCE_SAGA_GET_PLANNING_PREFERENCE';
export const PREFERENCE_GET_PLANNING_PREFERENCE_REQUEST = 'PREFERENCE_GET_PLANNING_PREFERENCE_REQUEST';
export const PREFERENCE_GET_PLANNING_PREFERENCE_SUCCESS = 'PREFERENCE_GET_PLANNING_PREFERENCE_SUCCESS';
export const PREFERENCE_GET_PLANNING_PREFERENCE_FAILURE = 'PREFERENCE_GET_PLANNING_PREFERENCE_FAILURE';
export const PREFERENCE_SAGA_CREATE_PLANNING_PREFERENCE = 'PREFERENCE_SAGA_CREATE_PLANNING_PREFERENCE';
export const PREFERENCE_CREATE_PLANNING_PREFERENCE_REQUEST = 'PREFERENCE_CREATE_PLANNING_PREFERENCE_REQUEST';
export const PREFERENCE_CREATE_PLANNING_PREFERENCE_SUCCESS = 'PREFERENCE_CREATE_PLANNING_PREFERENCE_SUCCESS';
export const PREFERENCE_CREATE_PLANNING_PREFERENCE_FAILURE = 'PREFERENCE_CREATE_PLANNING_PREFERENCE_FAILURE';
export const PREFERENCE_SAGA_DEL_PLANNING_PREFERENCE = 'PREFERENCE_SAGA_DEL_PLANNING_PREFERENCE';
export const PREFERENCE_DEL_PLANNING_PREFERENCE_REQUEST = 'PREFERENCE_DEL_PLANNING_PREFERENCE_REQUEST';
export const PREFERENCE_DEL_PLANNING_PREFERENCE_SUCCESS = 'PREFERENCE_DEL_PLANNING_PREFERENCE_SUCCESS';
export const PREFERENCE_DEL_PLANNING_PREFERENCE_FAILURE = 'PREFERENCE_DEL_PLANNING_PREFERENCE_FAILURE';
export const PREFERENCE_FAILURE = 'PREFERENCE_FAILURE';
