import React, { Component } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from './pages/Login/Login';
import Feedback from './pages/Feedback/Feedback';
// eslint-disable-next-line import/no-cycle
import PrivateRouteGroup from './components/Helpers/PrivateRouteGroup';
import ForgotPassword from './pages/ChangePasswordPincode/ChangePasswordPincode';
import { getProfileOfCurrentUser } from './redux/user/userActions';
import { setAppError } from './redux/app/appActions';
import ErrorPage from './pages/ErrorPage';
import CacheBuster from './components/CacheBuster/CacheBuster';
import { translate } from './helpers/translations/translator';
import { RegistrationPage } from './@paco/pages';
import { UserStatus } from './@paco/types/userStatus';
import { InactiveUserRouteGroup } from './@paco/containers';
import { LoadingSpinner } from './@paco/components';

class App extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        this.isAuthenticated() && dispatch(getProfileOfCurrentUser());
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location) {
            window.scrollTo(0, 0);
        }
    }

    isAuthenticated() {
        const { apiToken } = this.props;
        return !!apiToken;
    }

    renderApp() {
        const {
            apiToken,
            appError,
            dispatch,
            currentUser,
        } = this.props;

        if (apiToken && !currentUser) {
            return (
                <LoadingSpinner />
            )
        }

        if (currentUser && currentUser.status === UserStatus.inActive) {
            return (
                <InactiveUserRouteGroup />
            );
        }

        if (appError) {
            return (
                <ErrorPage
                    errorCode={appError.code}
                    fullHeight
                    redirectTo="/"
                    onBackClick={() => dispatch(setAppError(null))}
                />
            );
        }

        return (
            <Switch>
                <Route exact path={`/${translate('nav.login.link')}`} component={Login} />
                <Route exact path={`/${translate('nav.feedback.link')}/:feedbackUuid`} component={Feedback} />
                <Route exact path={`/${translate('nav.completeRegistration.link')}`} component={RegistrationPage} />
                <Route exact path={`/${translate('nav.forgotPassword.link')}`} component={ForgotPassword} />
                <PrivateRouteGroup path="/" isAuthenticated={this.isAuthenticated()} />
            </Switch>
        );
    }

    render() {
        return (
            <CacheBuster>
                {this.renderApp()}
            </CacheBuster>
        );
    }
}

App.propTypes = {
    apiToken: PropTypes.string,
    location: PropTypes.object,
    appError: PropTypes.object,
    currentUser: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
};
App.defaultProps = {
    apiToken: null,
    appError: null,
    location: null,
    currentUser: null,
};

function mapStateToProps(state) {
    return {
        apiToken: state.authenticationReducer.apiToken,
        appError: state.appReducer.error,
        currentUser: state.userReducer.currentUser,
    };
}

export default hot(connect(mapStateToProps)(App));
