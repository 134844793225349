import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { getLeaveOfAbsencesDetailApiCall, cancelLeaveOfAbsenceApiCall } from '../../entities/LeaveOfAbsences/LeaveOfAbsencesService';
import { TypedDispatch } from '../store';
import { setToast } from '../toasts/toastsReducer';
import { ToastType } from '../../types/toastTypes';
import trans from '../../helpers/trans';
import {
    setIsLoading,
    setLeaveOfAbsenceRequestDetail,
    setIsSuccessful,
    setError,
} from './leaveOfAbsenceRequestDetailReducer';

export const fetchLeaveOfAbsencesDetail = (id: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const response = await getLeaveOfAbsencesDetailApiCall(id);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setIsLoading(false));

            return;
        }

        dispatch(setLeaveOfAbsenceRequestDetail(response.data));
    } catch (error) {
        console.error('[fetchLeaveOfAbsencesDetail]', error);

        dispatch(setError('Error fetching data'));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const cancelLeaveOfAbsence = (id: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));

    try {
        const response = await cancelLeaveOfAbsenceApiCall(id);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setIsLoading(false));

            return;
        }

        dispatch(setToast({
            text: trans('containers.leaveOfAbsenceDetailContainer.leaveOfAbsenceRequestCancel'),
            type: ToastType.pass,
        }));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[cancelLeaveOfAbsence]', error);
        dispatch(setError('Error cancelling leave of absence'));
    } finally {
        dispatch(setIsLoading(false));
    }
};
