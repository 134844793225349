import axios from 'axios';
import { formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';

export const getTracksForMe = (includes = [], startDate = null, endDate = null) => axios.get(
    `${process.env.REACT_APP_API_URL}/me/tracks`,
    {
        params: {
            include: includes.join(','),
            startDate: formatDate(startDate, DateFormat.apiDateTime),
            endDate: formatDate(endDate, DateFormat.apiDateTime),
        },
    },
)

export const getTrack = (includes = [], trackUuid = null) => axios.get(
    `${process.env.REACT_APP_API_URL}/tracks/${trackUuid}`,
    {
        params: {
            include: includes.join(','),
        },
    },
);
