import { TypedDispatch } from '../store';
import {
    setAccessToken,
    setError,
    setIsGetAccessTokensSuccessful,
    setIsLoading,
    setIsSuccessful,
} from './registrationReducer'
import { fetchAccessTokenWithRegistrationToken } from '../../entities/Authentication/AuthenticationService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { UserFormData } from '../../entities/User/User';
import { patchUserProfileApiCall } from '../../entities/User/UserService';
import { patchPersonApiCall } from '../../entities/Person/PersonService';

export const getAccessTokenWithRegistrationToken = (userId: string, confirmId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await fetchAccessTokenWithRegistrationToken(userId, confirmId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));
            dispatch(setIsLoading(false));

            return;
        }

        dispatch(setAccessToken(response.data));
        dispatch(setIsGetAccessTokensSuccessful(true));
    } catch (error) {
        console.error('[getAccessTokenWithRegistrationToken]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
}

export const submitRegistration = (formData: UserFormData, userId: string, personId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const [userResponse, personResponse] = await Promise.all([
            patchUserProfileApiCall(formData, userId),
            patchPersonApiCall(formData, personId),
        ]);

        if (!isFetchResultSuccessful(userResponse)) {
            dispatch(setIsLoading(false));
            dispatch(setError(userResponse.error));

            return;
        }

        if (!isFetchResultSuccessful(personResponse)) {
            dispatch(setIsLoading(false));
            dispatch(setError(personResponse.error));

            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[submitRegistration]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
}
