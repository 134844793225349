import React, { FC, ReactElement } from 'react';

import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { AddLeaveOfAbsenceForm } from '../../../containers';
import { addLeaveOfAbsence } from '../../../redux/leaveOfAbsencesRequests/leaveOfAbsencesRequestsActions';
import { LeaveOfAbsenceForm } from '../../../entities/LeaveOfAbsences/LeaveOfAbsences';

const ConnectedLeaveRequestForm: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const { isLoading } = useTypedSelector(state => state.pacoLeaveOfAbsencesRequestsReducer);
    const { selectedDays } = useTypedSelector(state => state.calendarReducer);

    const handleSubmit = (formData: LeaveOfAbsenceForm): void => {
        dispatch(addLeaveOfAbsence(formData));
    };

    return (
        <AddLeaveOfAbsenceForm
            startDate={selectedDays[0]}
            endDate={selectedDays[selectedDays.length - 1]}
            onSubmit={handleSubmit}
            isLoading={isLoading}
        />
    );
};

export default ConnectedLeaveRequestForm;
