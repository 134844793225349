import { UserFormData } from '../../../@paco/entities/User/User';
import { translate } from '../../../helpers/translations/translator';

export interface ValidationErrors {
    employeeNumber?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
    phone?: string;
    password?: string;
    passwordRepeat?: string;
    pincode?: string;
    pincodeRepeat?: string;
}

function validatePassword(password: string): boolean {
    const passwordRegEx = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,}$/

    if (!password) {
        return false;
    }

    return (password.search(passwordRegEx) === 0 && password.length > 7);
}

function validatePincode(pincode: string): boolean {
    if (!pincode) {
        return false;
    }

    if (pincode.match(/^[0-9]+$/) === null) {
        return false;
    }

    return pincode.length === 4;
}

function validatePasswordRepeat(password: string, passwordRepeat?: string): boolean {
    return password === passwordRepeat;
}

function validatePincodeRepeat(pincode: string, pincodeRepeat?: string): boolean {
    return pincode === pincodeRepeat;
}

function validatePhone(phone: string): boolean {
    return !!(phone && phone.length >= 10);
}


export function validateVerifyRegistrationData(
    formData: UserFormData,
    submitted: boolean,
): ValidationErrors {
    const errors: ValidationErrors = {};

    if (!submitted) {
        return {};
    }

    if (!formData.firstname) {
        errors.firstname = translate('pages.registration.isRequired', {
            item: translate('common.firstName'),
        });
    }

    if (!formData.lastname) {
        errors.lastname = translate('pages.registration.isRequired', {
            item: translate('common.lastName'),
        });
    }

    if (!validatePhone(formData.phone)) {
        errors.phone = translate('pages.registration.phoneNotValid');
    }

    return errors;
}

export function validatePasswordRegistrationData(
    formData: UserFormData,
    submitted: boolean,
    isCapsLockPressed?: boolean,
): ValidationErrors {
    const errors: ValidationErrors = {};

    if (isCapsLockPressed) {
        errors.password = formData.password ? translate('pages.registration.capsLockWarning') : undefined;
        errors.passwordRepeat = formData.passwordRepeat ? translate('pages.registration.capsLockWarning') : undefined;

        return errors;
    }

    if (!submitted) {
        return {};
    }

    if (!validatePassword(formData.password)) {
        errors.password = translate('pages.registration.passwordRequirements');
    }

    if (!validatePasswordRepeat(formData.password, formData.passwordRepeat)) {
        errors.passwordRepeat = translate('pages.registration.passwordNotSame');
    }

    return errors;
}

export function validatePincodeRegistrationData(
    formData: UserFormData,
    submitted: boolean,
): ValidationErrors {
    const errors: ValidationErrors = {};

    if (!submitted) {
        return {};
    }

    if (!validatePincode(formData.pincode)) {
        errors.pincode = translate('pages.registration.pincodeRequirements');
    }

    if (!validatePincodeRepeat(formData.pincode, formData.pincodeRepeat)) {
        errors.pincodeRepeat = translate('pages.registration.pincodeNotSame');
    }

    return errors;
}
