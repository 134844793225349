import React, { FC, ReactElement } from 'react';

import { ConnectedLeaveOfAbsences } from '../../connectors';

import { Page } from '../../components';

const LeaveOfAbsencesPage: FC = (): ReactElement => (
    <Page className="leave-of-absences-page">
        <ConnectedLeaveOfAbsences />
    </Page>
);

export default LeaveOfAbsencesPage;
