import { addDays, max } from '../../helpers/date';
import { transformToPeriod } from '../Period/PeriodTransformers';
import { Absence, AbsenceResource } from './Absence';

export const transformToAbsence = (resource: AbsenceResource): Absence => {
    const { start, end } = resource.attributes;
    const startDate = new Date(start);
    const justifiedEndDate = !end ? addDays(max([startDate, new Date()]), 3) : new Date(end);

    return {
        id: resource.id,
        period: transformToPeriod(
            startDate,
            justifiedEndDate,
            !!end,
        ),
    };
};
