import moment from 'moment/moment';
import { LocaleSpecification } from 'moment';
import { addDays, differenceInDays } from '../../@paco/helpers/date';
import { translate } from '../translations/translator';
import { LeaveOfAbsenceViewModel } from '../../models';
import { convertDateToDateYearString } from '../DateTimeParser';
import UpToAndIncludingDate from '../UpToAndIncludingDate';
import { Locale } from '../../@paco/helpers/trans';

const getDateString = (startDate: Date, endDate?: UpToAndIncludingDate): string => {
    const formattedStartDate = convertDateToDateYearString(startDate);

    if (!endDate) {
        return `${translate('pages.absences.from')} ${formattedStartDate}`;
    }

    const daysBetween = differenceInDays(endDate.date, startDate);

    if (daysBetween === 0) {
        return formattedStartDate;
    }

    const formattedEndDate = convertDateToDateYearString(addDays(startDate, daysBetween));

    return `${formattedStartDate} ${translate('pages.absences.until')} ${formattedEndDate}`;
}

export const getLeaveOfAbsenceDateString = (leaveOfAbsence: LeaveOfAbsenceViewModel): string => (
    getDateString(leaveOfAbsence.start, leaveOfAbsence.end)
);

export const getLocaleSpecification = (): LocaleSpecification => {
    const weekdays = [
        translate('common.weekDays.sun'),
        translate('common.weekDays.mon'),
        translate('common.weekDays.tue'),
        translate('common.weekDays.wed'),
        translate('common.weekDays.thu'),
        translate('common.weekDays.fri'),
        translate('common.weekDays.sat'),
    ];

    const weekdaysShort = weekdays.map(weekday => weekday.substring(0, 3));
    const weekdaysMin = weekdays.map(weekday => weekday.substring(0, 2));

    const months = [
        translate('common.months.jan'),
        translate('common.months.feb'),
        translate('common.months.mar'),
        translate('common.months.apr'),
        translate('common.months.may'),
        translate('common.months.jun'),
        translate('common.months.jul'),
        translate('common.months.aug'),
        translate('common.months.sep'),
        translate('common.months.oct'),
        translate('common.months.nov'),
        translate('common.months.dec'),
    ];

    const monthsShort = months.map(month => month.substring(0, 3));

    return {
        weekdays,
        weekdaysShort,
        weekdaysMin,
        months,
        monthsShort,
    }
}

export const localizeMoment = (locale: Locale): void => {
    const localeSpecification = getLocaleSpecification();

    moment.updateLocale(locale, localeSpecification);
}
