import React, {
    ChangeEvent,
    FC,
    FormEvent,
    ReactElement,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import trans from '../../../helpers/trans';
import {
    Button,
    InfoListBlock,
    InfoListItem,
    Input,
    LoadingSpinner,
} from '../../../components';
import { DateInput } from '../../../compositions';
import { LeaveOfAbsenceForm } from '../../../entities/LeaveOfAbsences/LeaveOfAbsences';
import { customCompareLeaveRequestDate } from './helpers/CustomCompareLeaveRequestDate';

import './AddLeaveOfAbsenceForm.scss';

interface AddLeaveOfAbsenceFormProps {
    isLoading?: boolean;
    startDate: Date;
    endDate: Date;
    onSubmit: (formData: LeaveOfAbsenceForm) => void;
    className?: string;
}

const AddLeaveOfAbsenceForm: FC<AddLeaveOfAbsenceFormProps> = ({
    isLoading = false,
    startDate: formStartDate,
    endDate: formEndDate,
    onSubmit,
    className = '',
}): ReactElement => {
    const [startDate, setStartDate] = useState<Date>(formStartDate);
    const [endDate, setEndDate] = useState<Date>(formEndDate);
    const [reason, setReason] = useState<string>('');

    const history = useHistory();

    const endDateValid = customCompareLeaveRequestDate(startDate, endDate) !== -1;

    const canSubmit = !endDateValid && reason.length > 0;

    const handleFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        onSubmit({ startDate, endDate, reason });

        history.push(`/${trans('nav.absences.link')}/${trans('nav.leaveOfAbsenceRequests.link')}`);
    }

    const handleReasonChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setReason(event.target.value);
    }

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <form
            onSubmit={handleFormSubmit}
            className={`add-leave-of-absence-form ${className}`}
        >
            <ul className="add-leave-of-absence-form__list">
                <InfoListItem
                    label={trans('containers.leaveOfAbsenceDetailContainer.leaveOfAbsenceStartDate')}
                    className="add-leave-of-absence-form__list-item"
                >
                    <DateInput
                        hideLabel
                        label={trans('common.from')}
                        type="date"
                        value={startDate}
                        onChange={setStartDate}
                    />
                </InfoListItem>

                <InfoListItem
                    label={trans('containers.leaveOfAbsenceDetailContainer.leaveOfAbsenceEndDate')}
                    className="add-leave-of-absence-form__list-item"
                >
                    <DateInput
                        hideLabel
                        label={trans('common.from')}
                        type="date"
                        value={endDate}
                        onChange={setEndDate}
                    />
                </InfoListItem>

                <InfoListBlock
                    label={trans('containers.leaveOfAbsenceDetailContainer.leaveOfAbsenceReason')}
                    className="add-leave-of-absence-form__list-block"
                >
                    <Input
                        onChange={handleReasonChange}
                        placeholder={trans('common.descriptionOfThisRequest')}
                        className="add-leave-of-absence-form__textarea-input"
                    />
                </InfoListBlock>
            </ul>

            <Button
                disabled={!canSubmit}
                type="submit"
                className="add-leave-of-absence-form__submit-button"
                text={trans('common.request')}
            />
        </form>
    );
};

export default AddLeaveOfAbsenceForm;
