import React, { FC, ReactElement } from 'react';

import { LeaveOfAbsenceListItem } from '../index';
import { LeaveOfAbsence } from '../../../../entities/LeaveOfAbsences/LeaveOfAbsences';

interface LeaveOfAbsenceListProps {
    leaveOfAbsences: LeaveOfAbsence[];
    className?: string;
}

const LeaveOfAbsenceList: FC<LeaveOfAbsenceListProps> = ({
    leaveOfAbsences,
    className,
}): ReactElement => (
    <ul className={`leave-of-absence-list ${className}`}>
        {leaveOfAbsences.map(leaveOfAbsence => (
            <LeaveOfAbsenceListItem
                key={leaveOfAbsence.id}
                leaveOfAbsence={leaveOfAbsence}
                className="leave-of-absence-list__list-item"
            />
        ))}
    </ul>
);

export default LeaveOfAbsenceList;
