import React, { FC } from 'react';

import { LeaveOfAbsence } from '../../entities/LeaveOfAbsences/LeaveOfAbsences'
import { leaveOfAbsenceStatus, LeaveOfAbsenceStatusTypes } from '../../types/LeaveOfAbsenceStatusTypes';
import { LeaveOfAbsenceDetailList } from './subcomponents/index';
import trans from '../../helpers/trans';
import { Card, LoadingSpinner, Button } from '../../components';

import './LeaveOfAbsenceDetailContainer.scss';

interface LeaveOfAbsenceDetailContainerProps {
    cancelLeaveOfAbsence: (id: string) => void;
    leaveOfAbsence?: LeaveOfAbsence;
    isLoading: boolean;
    className?: string;
}

const LeaveOfAbsenceDetailContainer: FC<LeaveOfAbsenceDetailContainerProps> = ({
    cancelLeaveOfAbsence,
    leaveOfAbsence,
    isLoading,
    className = '',
}) => {
    const emptyState = !leaveOfAbsence || Object.keys(leaveOfAbsence).length === 0;

    const status = leaveOfAbsence ? leaveOfAbsence.status : {} as LeaveOfAbsenceStatusTypes;
    const { color } = leaveOfAbsenceStatus[status] || {};

    const isAbsenceRequestOpen = status === LeaveOfAbsenceStatusTypes.open;

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div className={`leave-of-absence-detail ${className}`}>
            <Card
                color={color}
                className="leave-of-absence-detail__card"
            />

            {!isLoading && !emptyState && (
                <LeaveOfAbsenceDetailList
                    leaveOfAbsence={leaveOfAbsence}
                />
            )}

            {isAbsenceRequestOpen && (
                <Button
                    text={trans('containers.leaveOfAbsenceDetailContainer.leaveOfAbsenceRequestRevoke')}
                    className="leave-of-absence-detail__cancel-button"
                    onClick={() => cancelLeaveOfAbsence(leaveOfAbsence?.id || '')}
                />
            )}
        </div>
    );
};

export default LeaveOfAbsenceDetailContainer;
