import React, { FC, ReactElement } from 'react';

import { ConnectedLeaveOfAbsenceDetail } from '../../connectors';

import { Page } from '../../components';

const AbsenceDetailPage: FC = (): ReactElement => (
    <Page className="absence-detail-page">
        <ConnectedLeaveOfAbsenceDetail />
    </Page>
);

export default AbsenceDetailPage;
