import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncReduxState, initialAsyncReduxState } from '../@config/AsyncReduxState';
import { LeaveOfAbsence } from '../../entities/LeaveOfAbsences/LeaveOfAbsences';

export interface LeaveOfAbsencesRequestsState extends AsyncReduxState<{}> {
    leaveOfAbsencesRequests: LeaveOfAbsence[];
}

const initialState: LeaveOfAbsencesRequestsState = {
    ...initialAsyncReduxState,
    leaveOfAbsencesRequests: [],
};

const leaveOfAbsencesRequestsSlice = createSlice({
    name: 'pacoLeaveOfAbsencesRequestsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): LeaveOfAbsencesRequestsState {
            return ({
                ...state,
                isLoading: action.payload,
            });
        },
        setLeaveOfAbsencesRequest(state, action: PayloadAction<LeaveOfAbsence[]>): LeaveOfAbsencesRequestsState {
            return ({
                ...state,
                leaveOfAbsencesRequests: action.payload,
            });
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): LeaveOfAbsencesRequestsState {
            return ({
                ...state,
                isSuccessful: action.payload,
            });
        },
        setError(state, action: PayloadAction<string>): LeaveOfAbsencesRequestsState {
            return ({
                ...state,
                error: action.payload,
            });
        },
    },
});

export const {
    setIsLoading,
    setLeaveOfAbsencesRequest,
    setIsSuccessful,
    setError,
} = leaveOfAbsencesRequestsSlice.actions;

export default leaveOfAbsencesRequestsSlice.reducer;
