import React, { FC, ReactElement, useEffect } from 'react';

import { AbsencesContainer } from '../../containers';

import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { getAbsences } from '../../redux/absences/absencesActions';

const ConnectedAbsences: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const { absences, isLoading } = useTypedSelector((state) => state.pacoAbsenceReducer);
    const { year } = useTypedSelector(state => state.calendarReducer);

    useEffect(() => {
        dispatch(getAbsences(year));
    }, [year]);

    return (
        <AbsencesContainer
            absences={absences}
            loading={isLoading}
        />
    );
}

export default ConnectedAbsences;
