import { Period } from '../Period/Period';
import { formatDate } from '../../helpers/date';
import { DateFormat } from '../../types/dateFormatTypes';
import { translate } from '../../../helpers/translations/translator';

export const getAbsenceDateLabel = (period: Period): string => {
    const formattedStartDate = formatDate(period.start, DateFormat.dateWithStandAloneMonth);

    if (!period.hasEnd) {
        return `${translate('pages.absences.from')} ${formattedStartDate}`;
    }

    const formattedEndDate = formatDate(period.end, DateFormat.dateWithStandAloneMonth);

    return `${formattedStartDate} ${translate('pages.absences.until')} ${formattedEndDate}`;
}
