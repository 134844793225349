import {
    SHIFTS_FAILURE,
    SHIFTS_REQUEST,
    SHIFTS_OPEN_SUCCESS,
    SHIFTS_PLANNED_SUCCESS,
    SHIFTS_SET_TAB,
    APP_LOGOUT,
} from '../actionTypes';

const initialState = {
    activeTab: null,
    plannedShifts: [],
    openShifts: [],
    currentShift: null,
    loading: false,
    errors: [],
};

const shiftsReducer = (state = initialState, action = null) => {
    switch (action.type) {
    case SHIFTS_REQUEST:
        return { ...state, loading: true };
    case SHIFTS_PLANNED_SUCCESS:
        return {
            ...state, loading: false, plannedShifts: action.plannedShifts,
        };
    case SHIFTS_OPEN_SUCCESS:
        return {
            ...state, loading: false, openShifts: action.openShifts,
        };
    case SHIFTS_FAILURE:
        return {
            ...state, loading: false, errors: action.errors,
        };
    case SHIFTS_SET_TAB:
        return {
            ...state, activeTab: action.tab,
        };
    case APP_LOGOUT:
        return { ...initialState };
    default:
        return state;
    }
};

export default shiftsReducer;
