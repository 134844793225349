import axios from 'axios';
import Jsona from 'jsona/lib/Jsona';
import { formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';

const jsonApiDataFormatter = new Jsona();

export const getPlannedShifts = (includes = [], startDate = null, endDate = null) => axios.get(
    `${process.env.REACT_APP_API_URL}/me/shifts/planned`,
    {
        params: {
            include: includes.join(','),
            startDate: formatDate(startDate, DateFormat.apiDateTime),
            endDate: formatDate(endDate, DateFormat.apiDateTime),
        },
    },
);

export const getOpenShifts = (includes = [], startDate = null, endDate = null) => axios.get(
    `${process.env.REACT_APP_API_URL}/me/shifts/open`,
    {
        params: {
            include: includes.join(','),
            startDate: formatDate(startDate, DateFormat.apiDateTime),
            endDate: formatDate(endDate, DateFormat.apiDateTime),
        },
    },
);

export const getShift = (includes = [], shiftUuid = null) => axios.get(
    `${process.env.REACT_APP_API_URL}/shifts/${shiftUuid}`,
    {
        params: {
            include: includes.join(','),
        },
    },
);

export const registerShift = shiftId => axios.post(
    `${process.env.REACT_APP_API_URL}/me/shift/register/${shiftId}`,
);

export const unregisterShift = shift => axios.delete(
    `${process.env.REACT_APP_API_URL}/me/shift/register/${shift.id}`,
);

export const offerShift = shiftId => axios.post(
    `${process.env.REACT_APP_API_URL}/me/shift/offer/${shiftId}`,
);

export const cancelShiftOffer = shift => axios.delete(
    `${process.env.REACT_APP_API_URL}/me/shift/offer/${shift.id}`,
);

export const registerShiftOffer = shift => axios.post(
    `${process.env.REACT_APP_API_URL}/me/shift/register-offer/${shift.id}`,
);

export const unregisterShiftOffer = shift => axios.delete(
    `${process.env.REACT_APP_API_URL}/me/shift/register-offer/${shift.id}`,
);

export const addCommentToShift = comment => axios.post(
    `${process.env.REACT_APP_API_URL}/me/comment`, jsonApiDataFormatter.serialize({ stuff: comment, includeNames: ['shiftPlannings'] }),
);
