import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Absence } from '../../entities/Absence/Absence';
import { AsyncReduxState, initialAsyncReduxState } from '../@config/AsyncReduxState';

export interface AbsencesState extends AsyncReduxState<{}> {
    absences: Absence[];
}

const initialState: AbsencesState = {
    ...initialAsyncReduxState,
    absences: [],
};

const absencesSlice = createSlice({
    name: 'pacoAbsenceReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): AbsencesState {
            return ({
                ...state,
                isLoading: action.payload,
            });
        },
        setAbsences(state, action: PayloadAction<Absence[]>): AbsencesState {
            return ({
                ...state,
                absences: action.payload,
            });
        },
        setError(state, action: PayloadAction<string>): AbsencesState {
            return ({
                ...state,
                error: action.payload,
            });
        },
    },
});

export const {
    setIsLoading,
    setAbsences,
    setError,
} = absencesSlice.actions;

export default absencesSlice.reducer;
