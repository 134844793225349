import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { leaveOfAbsenceStatus, LeaveOfAbsenceStatusTypes } from '../../../../types/LeaveOfAbsenceStatusTypes';
import ListGroupItem from '../../../../components/@list/ListGroupItem/ListGroupItem';
import { getLeaveOfAbsencesDateLabel } from '../../../../entities/LeaveOfAbsences/LeaveOfAbsencesHelpers';
import { LeaveOfAbsence } from '../../../../entities/LeaveOfAbsences/LeaveOfAbsences';
import { Badge } from '../../../../components';
import trans from '../../../../helpers/trans';

import './LeaveOfAbsenceListItem.scss';

interface LeaveOfAbsenceListItemProps {
    leaveOfAbsence: LeaveOfAbsence;
    className?: string;
}

const LeaveOfAbsenceListItem: FC<LeaveOfAbsenceListItemProps> = ({ leaveOfAbsence, className }) => {
    const status = leaveOfAbsence.status as LeaveOfAbsenceStatusTypes;
    const { label, color } = leaveOfAbsenceStatus[status];

    return (
        <ListGroupItem
            className={`leave-of-absence-list-item ${className}`}
        >
            <NavLink
                to={`/${trans('nav.absences.link')}/${leaveOfAbsence.id}`}
                className="leave-of-absence-list-item__link"
            />

            <div className="leave-of-absence-list-item__wrapper">
                <p className="leave-of-absence-list-item__title">{leaveOfAbsence.reason}</p>
                <p className="leave-of-absence-list-item__date-label">{getLeaveOfAbsencesDateLabel(leaveOfAbsence.period.start, leaveOfAbsence.period.end)}</p>
            </div>

            <Badge color={color}>
                {label}
            </Badge>
        </ListGroupItem>
    );
};

export default LeaveOfAbsenceListItem;
