import { combineReducers } from 'redux';

import pacoReducers, { Reducers as PacoReducers } from '../@paco/redux/reducers';
import authenticationReducer from './authentication/authenticationReducer';
import tracksReducer from './tracks/tracksReducer';
import trackReducer from './track/trackReducer';
import shiftsReducer from './shifts/shiftsReducer';
import shiftReducer from './shift/shiftReducer';
import exchangesReducer from './exchanges/exchangesReducer';
import appReducer from './app/appReducer';
import absencesReducer from './absences/absencesReducer';
import userReducer from './user/userReducer';
import feedbackReducer from './feedback/feedbackReducer';
import registrationReducer from './registration/registrationReducer';
import documentsReducer from './documents/documentsReducer';
import preferenceReducer from './preferences/preferenceReducer';
import { AppReducer } from './app-ts/appModels';
import { DocumentsReducer } from './documents-ts/documentsModels';
import { ShiftReducer } from './shift/shiftModels';

export interface Reducers extends PacoReducers {
    appReducer: AppReducer;
    authenticationReducer: any;
    tracksReducer: any;
    trackReducer: any;
    shiftsReducer: any;
    shiftReducer: ShiftReducer;
    exchangesReducer: any;
    absencesReducer: any;
    userReducer: any;
    feedbackReducer: any;
    registrationReducer: any;
    documentsReducer: DocumentsReducer;
    preferenceReducer: any;
}

export default combineReducers({
    appReducer,
    authenticationReducer,
    tracksReducer,
    trackReducer,
    shiftsReducer,
    shiftReducer,
    exchangesReducer,
    userReducer,
    absencesReducer,
    feedbackReducer,
    registrationReducer,
    documentsReducer,
    preferenceReducer,
    ...pacoReducers,
});
